import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { ArrowBack } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import AppNav from '../../components/appNav';

import Payer from './Payer';
import PayerInform from './PayerInform';
import TypeSubcriber from './TypeSubscribers';
import AddSubscriber from './AddSubscriber';
import FinsihRegistration from './FinsihRegistration';

import { api } from '../../services/api'
import { useEvent } from '../../context/EventContext';
import { FormContext  } from '../../context/FormContext';
import { OrderContext  } from '../../context/OrderContext';

import { isWithinInterval, parseISO } from 'date-fns'

function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Todos os direitos reservados © '}
        <Link color="inherit" href="https://softtechcloud.com.br/">
          SoftTech 
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  
  const steps = ['Pagador', 'Informações do Pagador', 'Tipo de Inscrição','Adicionar Inscritos','Revisar Inscrição'];
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Payer/>;
      case 1:
        return <PayerInform />;
      case 2: 
        return <TypeSubcriber/>
      case 3:
        return <AddSubscriber />;
      case 4: 
        return <FinsihRegistration />;
      default:
        throw new Error('Unknown step');
    }
  }
  
  
  export default function SubscriberPage() {
    const { activeStep } = React.useContext(FormContext)
    let navigate = useNavigate()
    const { order, setOrder } = React.useContext(OrderContext)
    let { eventId } = useParams();
    const { events, setEvents } = useEvent()
    const info = useRef(null)
    const [stateAlert, setStateAlert] = React.useState({status:false, type: 'success', msg:''});

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
   
    async function getEventById(id){
        try{
            const eventbyId = await api.get('/events/'+id)
            setEvents(...[eventbyId.data])
            setOrder({...order, event: eventId})
          let startDate = parseISO(eventbyId.data.start_subscription)
          let endDate = parseISO(eventbyId.data.end_subscription)
          let today = new Date();
          let result = isWithinInterval(today,{start: startDate, end: endDate})
          if(result === -1){
            setStateAlert({status:true, type: 'error', msg:'AS INSCRIÇÕES PARA O EVENTO AINDA NÃO ESTÃO ABERTAS!'})
            setTimeout(() => {
              handleBack()
            }, 4000)
            return
          }
          
        }catch(error){
            console.log(error)
        }
    }
    async function getInfoConfig(){
      try{
        const inform = await api.get('/configs/1')
        info.current = inform.data.data.attributes
        console.log(info.current)
      }catch(error){
        console.log(error)
      }
    }

    function handleBack(){
        navigate(`/event/${eventId}`)
    }
    function handlePayment(){
      window.open(order[0].payment_url)
    }

    useLayoutEffect(() => {
       getEventById(eventId)
       getInfoConfig()
       return () => {
        setStateAlert({status:false, type: 'success', msg:''})
       }

    },[])

    return (
        <>
        <AppNav/>
        <AppBar
          position="relative"
          color="default"
          elevation={0}
          sx={{
            position: 'relative',
            borderBottom: (t) => `1px solid ${t.palette.divider}`,
          }}
        >
           <Snackbar
                open={stateAlert.status}
                anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
                onClose={() => setStateAlert({status: false, type: 'error', msg:'' } )} >
                    <Alert sx={{ width: '100%' }} severity={`${stateAlert.type}`}>
                        {stateAlert.msg}
                    </Alert>
            </Snackbar>
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap textAlign={'center'}>
              { events.name || "Nome do Evento"}
            </Typography>
          </Toolbar>
        </AppBar>
        <Stack direction={'row'} py={3} sx={{alignItems:'center'}}>
        <IconButton onClick={handleBack}>
            <ArrowBack color='error'/>
        </IconButton>
        <Typography variant={'body'} color='error'>Sair</Typography>
        </Stack>
        <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h4" align="center">
              Realize sua inscrição
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Obrigado pela sua inscrição.
                  </Typography>
                  <Typography variant="subtitle1">
                    {info.current !== null ?
                      <>
                      {order[0].payment_form === 'Boleto' && <>
                        <p> Recebemos sua inscrição.<br/>
                            A sua inscrição será confirmada mediante o pagamento do boleto.<br/>
                            Estas informações de confirmação foram enviadas para o e-mail da instituição pagadora cadastrado no sistema de inscrição.</p>
                        </>}
                      {order[0].payment_form === 'Pix' && 
                         <p> Recebemos sua inscrição.<br/>
                             A sua inscrição será confirmada mediante:<br/>
                              - Pagamento e envio do comprovante de pagamento para o email <a href={'mailto:'+info.current.email+''}>{info.current.email}</a> ou whatsapp <a href='https://api.whatsapp.com/send?phone=5551981287790&text=Ol%C3%A1,%20preciso%20de%20informa%C3%A7%C3%B5es'>{info.current.whatsapp}</a><br/>
                              ou<br/>
                              - Envio do empenho para o e-mail <a href={'mailto:'+info.current.email+''}>{info.current.email}</a> ou whatsapp <a href='https://api.whatsapp.com/send?phone=5551981287790&text=Ol%C3%A1,%20preciso%20de%20informa%C3%A7%C3%B5es'>{info.current.whatsapp}</a><br/>                 
                         <p>  A chave PIX é o <strong>CNPJ {info.current.pix}</strong>, em nome da <strong>{info.current.fantasy}</strong>.</p>
                         Estas informações de confirmação foram enviadas para  o e-mail da instituição pagadora cadastrado no sistema de inscrição.</p>}
                         
                      {order[0].payment_form === 'Transação ou Depósito Bancário' && <>
                       <p>Recebemos sua inscrição.<br/>
                          A sua inscrição será confirmada mediante:<br/>
                          - Pagamento e envio do comprovante de pagamento para o email <a href={'mailto:'+info.current.email+''}>{info.current.email}</a> ou whatsapp <a href='https://api.whatsapp.com/send?phone=5551981287790&text=Ol%C3%A1,%20preciso%20de%20informa%C3%A7%C3%B5es'>{info.current.whatsapp}</a><br/>
                          ou<br/>
                          - Envio do empenho para o e-mail <a href={'mailto:'+info.current.email+''}>{info.current.email}</a> ou whatsapp <a href='https://api.whatsapp.com/send?phone=5551981287790&text=Ol%C3%A1,%20preciso%20de%20informa%C3%A7%C3%B5es'>{info.current.whatsapp}</a>.</p>

                           <p>A conta para depósito ou transferência bancária é em nome de <strong>{info.current.social_reason.toUpperCase()} - {info.current.fantasy}</strong><br/>
                           <strong>
                              {info.current.bank.toUpperCase()}<br/>
                              AGÊNCIA {info.current.agency}  <br/>
                              OPERAÇÃO {info.current.operation}  <br/>
                              CONTA CORRENTE {info.current.cc}<br/>
                              CNPJ {info.current.document}
                            </strong>  
                          </p>
                          <p> Se preferir optar por PIX a chave é o <strong>CNPJ {info.current.pix}</strong>, em nome da <strong>{info.current.fantasy}</strong>.<br/>
                              Estas informações de confirmação foram enviadas para  o e-mail da instituição pagadora cadastrado no sistema de inscrição.
                          </p>
                          </>
                          }
                       <p>
                       Solicitação de recibo ou nota fiscal através do email <a href={'mailto:'+info.current.email+''}>{info.current.email}</a> ou whatsapp <a href='https://api.whatsapp.com/send?phone=5551981287790&text=Ol%C3%A1,%20preciso%20de%20informa%C3%A7%C3%B5es'>{info.current.whatsapp}</a>
                      </p>
                      </>
                  :
                    null
                  }
                  </Typography>
                  <Button 
                    size='medium'
                    variant="contained"
                    type={'button'}
                    color='success'
                    onClick={() => handleBack()}
                    sx={{ mt: 3, mr: 1  }}>
                      Voltar para evento
                  </Button>
                  {order[0].payment_form == "Boleto"  &&
                  <Button 
                    size='medium'
                    variant="contained"
                    type={'button'}
                    color='primary'
                    onClick={() => handlePayment()}
                    sx={{ mt: 3, mr: 1  }}>
                     Acessar boleto
                  </Button>
                  }
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
          <Copyright />
        </Container>
        </>
    );
  }