import React from 'react'
import { Box,Typography,Grid } from '@mui/material'
import AppNav from '../../components/appNav';

export default function Contact(){
    return(
        <Box sx={{width: "100vw", height:"100vh", px:0,m:0}}>
            <AppNav/>
            <Grid container my={20} columns={12} sx={{justifyContent:'center'}}>
                <Grid item sm={12} sx={{display:'flex',justifyContent:'center', alignText:'center'}}> 
                <div>
                    <Typography variant='h4'>Entre em contato</Typography>
                    <Typography variant='body'>Nosso canal de comunicação por enquanto é via e-mail</Typography>
                </div>
                </Grid>
                <Grid item py={4} sm={12} sx={{display:'flex',justifyContent:'center'}}> 
                    <a style={{textDecoration: 'none',  color:'black'}} href="mailto:agip@sinos.net"><Typography variant='h6' >agip@sinos.net</Typography></a>
                </Grid>
            </Grid>
        </Box>
    )
}