import React,{ useEffect} from 'react'
import { Box,Grid, CircularProgress} from '@mui/material'

import AppNav from '../../components/appNav';
import TableInfo from '../../components/TableInfo';

import { api,baseURL } from '../../services/api'


export default function Documents(){
    const [loading, setLoading] = React.useState(false);
    const [listFiles, setListFiles] = React.useState([])

    const columns = [
        { Header: "#", accessor: "icon", width: "100%" },
        { Header: "Certidão", accessor: "title", width: "100%" },
        { Header: "Baixar", accessor: "actions", width: "90%" },
    ];
    async function handleView({id}){
        try{
            const [files] = listFiles.filter(file => id == file.id)
            window.open(`${baseURL+files.attributes.file.data.attributes.url}`, '_blank');
        }catch(error){
            console.log(error)
            alert(error.message)
        }
    }

    async function handleFiles(){
        try{
            const files = await api.get('/documents?populate=*')
            if(files.status === 200){
                setListFiles(files.data.data)
                setLoading(false)
            }
        }catch(error){  
            alert(error.message)
            setLoading(false)
        }
    }

    useEffect(()=> { handleFiles() },[])
    return(
        <Box sx={{width: "100vw", height:"100vh", px:0,m:0}}>
            <AppNav/>
            <Grid container mt={12} sx={{justifyContent: 'center'}}>
                <Grid item sm={6} >
                    {loading ?
                        <Box sx={{ width:'100%', height: '10vh',display: 'flex', justifyContent:'center' }}>
                            <CircularProgress sx={{ alignSelf:'center'}} size={50}/>
                        </Box> 
                    :
                        <TableInfo title='Certidões' variant="secondary" view={handleView} rows={listFiles} columns={columns} />
                    }
                </Grid>
            </Grid>
        </Box>
    )
}