import { BrowserRouter, Routes, Route} from 'react-router-dom';

import Events from '../pages/Events'; 
import FormSubscriber from '../pages/FormSubscriber';
import EventDetails from '../pages/EventDetails';
import Contact from '../pages/Contact';
import Documents from '../pages/Documents';
import CheckinEvent from '../pages/Checkin';
import OldEvents from '../pages/OldEvents';
export const Rotas = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" exact element={<Events/>} />       
      <Route path="/subscribe">
        <Route path=":eventId" element={<FormSubscriber />} />
      </Route>
      <Route path="/event">
        <Route path=":eventId" element={<EventDetails />} />
      </Route>
      <Route path="/checkin/" >
        <Route path=":eventId" element={<CheckinEvent />} />
      </Route>
      <Route path="/contact" exact element={<Contact/>} /> 
      <Route path="/documents" exact element={<Documents/>} />  
      <Route path="/eventos-anteriores" exact element={<OldEvents/>} />  
    </Routes>
   </BrowserRouter>
  )
};