import * as React from 'react';
import * as yup from 'yup'
import axios from 'axios'

import { Typography,Grid,TextField, Box, Button} from '@mui/material';
import { useFormik } from 'formik';
import { FormContext } from '../../context/FormContext';
import { CompanyContext } from '../../context/CompanyContext';
import { OrderContext } from '../../context/OrderContext';
import { maskTEL, maskCEP } from '../../utils/mask';

const validationCompanySchema = yup.object({
  social_reason: yup
      .string()
      .required('Campo Necessário'),
  trade: yup
      .string(),
  cep: yup
      .string()
      .required('Campo Necessário'),
  number: yup
      .string()
      .required('Campo Necessário'),
  address: yup
      .string()
      .required('Campo Necessário'),
  complement: yup
      .string(),
  email: yup
      .string()
      .email('E-mail não é válido')
      .required('Campo Necessário'),
  district: yup
      .string()
      .required('Campo Necessário'),
  state: yup
    .string()
    .required('Campo Necessário'),
  city: yup
    .string()
    .required('Campo Necessário'),
  phone: yup 
    .string()
    .required('Campo Necessário')
})

export default function PayerInform() {
  const { company, setCompany } = React.useContext(CompanyContext);
  const { order, setOrder } = React.useContext(OrderContext);
  const { activeStep, setActiveStep } = React.useContext(FormContext);
  
  const companyValidation = useFormik({
    initialValues: {
      document: company.document,
      email: company.email || "",
      address: company.address || "",
      phone:company.phone ||  "",
      whatsapp: company.whatsapp || "",
      trade: company.trade || "",
      number: company.number || "",
      social_reason: company.social_reason || "",
      complement: company.complement || "",
      cep: company.cep || "",
      city: company.city ||  "",
      state: company.state || "",
      district: company.district || ""
    },
    validationSchema: validationCompanySchema,
    onSubmit: values => {
      console.log(values)
      setCompany({...company,...values})
      setOrder({...order, company: values})
      setActiveStep(activeStep + 1)

    },
  });
  async function handleCep(cep){
    companyValidation.setFieldValue('cep', maskCEP(cep)) 
    const searchByCep = 'https://cep.awesomeapi.com.br/json/'+cep
    if(cep.length >= 8) {
      try {
        const dataByCep = await axios.get(searchByCep)
          console.log(dataByCep.data)
          companyValidation.setFieldValue('cep', maskCEP(cep)) 
          companyValidation.setFieldValue('city',  dataByCep.data.city)
          companyValidation.setFieldValue('state',  dataByCep.data.state) 
          companyValidation.setFieldValue('district', dataByCep.data.district) 
          companyValidation.setFieldValue('address', dataByCep.data.address)
      }catch(error){
         console.log(error)
      }
    }
  }
  async function handleChange(eventChange){
    const key = eventChange.target.name
    const value = eventChange.target.value
    console.log(company,companyValidation.values)
    switch(key){
      case 'cep':
        if(value.length > 9){ return }
          handleCep(value)
        break;
      case 'phone':
        if(value.length > 15){ return }
        companyValidation.setFieldValue('phone', maskTEL(value))
      break;
      default:
        companyValidation.setFieldValue(`${key}`, value)
      break; 
  }
  
}

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Dados da Instituição
      </Typography>
      <form onSubmit={companyValidation.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            id="social_reason"
            label="Razão Social"
            fullWidth
            variant="outlined"
            name="social_reason"
            value={companyValidation.values.social_reason}
            onChange={(e)=> handleChange(e)}
            error={companyValidation.touched.social_reason && companyValidation.errors.social_reason}
            helperText={companyValidation.touched.social_reason && companyValidation.errors.social_reason}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="trade"
            label="Nome Fantasia"
            fullWidth
            variant="outlined"
            name="trade"
            value={companyValidation.values.trade}
            onChange={(e)=> handleChange(e)}
            error={companyValidation.touched.trade && Boolean(companyValidation.errors.trade)}
            helperText={companyValidation.touched.trade && companyValidation.errors.trade}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="email"
            label="E-mail"
            fullWidth
            variant="outlined"
            name="email"
            value={companyValidation.values.email}
            onChange={(e)=> handleChange(e)}
            error={companyValidation.touched.email && Boolean(companyValidation.errors.email)}
            helperText={companyValidation.touched.email && companyValidation.errors.email}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="cep"
            label="CEP"
            fullWidth
            type={'tel'}
            variant="outlined"
            name="cep"
            InputLabelProps={{ shrink: true }}
            value={companyValidation.values.cep}
            onChange={(e)=> handleChange(e)}
            error={companyValidation.touched.cep && Boolean(companyValidation.errors.cep)}
            helperText={companyValidation.touched.cep && companyValidation.errors.cep}

          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="address"
            label="Endereço"
            fullWidth
            variant="outlined"
            name="address"
            InputLabelProps={{ shrink: true }}
            value={companyValidation.values.address}
            onChange={(e)=> handleChange(e)}
            error={companyValidation.touched.address && Boolean(companyValidation.errors.address)}
            helperText={companyValidation.touched.address && companyValidation.errors.address}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="number"
            label="Número"
            fullWidth
            variant="outlined"
            name="number"
            InputLabelProps={{ shrink: true }}
            value={companyValidation.values.number}
            onChange={(e)=> handleChange(e)}
            error={companyValidation.touched.number && Boolean(companyValidation.errors.number)}
            helperText={companyValidation.touched.number && companyValidation.errors.number}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="complement"
            label="Complemento"
            fullWidth
            variant="outlined"
            name="complement"
            InputLabelProps={{ shrink: true }}
            value={companyValidation.values.complement}
            onChange={(e)=> handleChange(e)}
            error={companyValidation.touched.complement && Boolean(companyValidation.errors.complement)}
            helperText={companyValidation.touched.complement && companyValidation.errors.complement}

          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="district"
            label="Bairro"
            fullWidth
            variant="outlined"
            name="district"
            InputLabelProps={{ shrink: true }}
            value={companyValidation.values.district}
            onChange={(e)=> handleChange(e)}
            error={companyValidation.touched.district && Boolean(companyValidation.errors.district)}
            helperText={companyValidation.touched.district && companyValidation.errors.district}
            
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="city"
            label="Cidade"
            fullWidth
            variant="outlined"
            name="city"
            InputLabelProps={{ shrink: true }}
            value={companyValidation.values.city}
            onChange={(e)=> handleChange(e)}
            error={companyValidation.touched.city && Boolean(companyValidation.errors.city)}
            helperText={companyValidation.touched.city && companyValidation.errors.city}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField
            id="state"
            label="Estado"
            fullWidth
            variant="outlined"
            name="state"
            InputLabelProps={{ shrink: true }}
            value={companyValidation.values.state}
            inputProps={{ maxLength: 2 }}
            onChange={(e)=> handleChange(e)}
            error={companyValidation.touched.state && Boolean(companyValidation.errors.state)}
            helperText={companyValidation.touched.state && companyValidation.errors.state}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="phone"
            label="Telefone"
            type={'tel'}
            fullWidth
            variant="outlined"
            name="phone"
            value={companyValidation.values.phone}
            onChange={(e)=> handleChange(e)}
            error={companyValidation.touched.phone && Boolean(companyValidation.errors.phone)}
            helperText={companyValidation.touched.phone && companyValidation.errors.phone}
            
          />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button 
                      variant="contained"
                      onClick={() => setActiveStep(activeStep-1)}
                      sx={{ mt: 3, ml: 1 }}>
                      Voltar
                    </Button>
                    <Button
                      variant="contained"
                      type={'submit'}
                      sx={{ mt: 3, ml: 1 }}>
                      Próximo
                    </Button>
                  </Box>
      </form>
    </React.Fragment>
  );
}