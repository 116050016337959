import React, { createContext, useState,  useContext } from 'react';

const OlderEventContext = createContext({});

export const OlderEventProvider = ({ children }) => {
    const [events, setEvents] = useState([]);    

    return (
      <OlderEventContext.Provider
        value={{ events, setEvents }}>
        {children}
      </OlderEventContext.Provider>
    );
  };
  
  export function useOlderEvent() {
    const context = useContext(OlderEventContext);
  
    return context;
  }
export default OlderEventContext;