import React from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import { FormContextProvider  } from '../../context/FormContext';
import { EventProvider  } from '../../context/EventContext';
import { CompanyContextProvider  } from '../../context/CompanyContext';
import { SubscribersProvider } from '../../context/SubscribersContext';
import { OrderContextProvider } from '../../context/OrderContext'
import SubscriberPage from './SubscriberPage';


export default function FormSubscriber() {
  return (
    <FormContextProvider>
      <EventProvider>
      <OrderContextProvider>
        <CompanyContextProvider>
          <SubscribersProvider>
            <CssBaseline />
            <SubscriberPage/>
          </SubscribersProvider>
        </CompanyContextProvider>
        </OrderContextProvider>
      </EventProvider>
     </FormContextProvider>
  );
}