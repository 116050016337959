/* eslint-disable array-callback-return */
import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Stack, IconButton } from "@mui/material"

import Article from '@mui/icons-material/Article'
import FileDownload from '@mui/icons-material/FileDownload'

export default function TableInfo(props) {
    const {columns, rows, view} = props
   
    const variants = [{
            type: 'success',
            color: 'linear-gradient(to left , #66BB6A, #43A047)',
        },
        {
            type: 'info',
            color: 'linear-gradient(to right , #1A73E8, #49a3f1)'
        },
        {   
            type: 'secondary',
            color: 'linear-gradient(to left , #747b8a, #495361)'
        }]

    function isVariant(){
        const [ variant ] =  variants.filter(variant => variant.type === props.variant)
        return variant.color
       }

    return (
        <Box sx={{ width: '100%', overflow: 'hidden',}} >
        <Box mx={'auto'} 
            sx={{width: '92%', height: '30px', display:'flex', color: 'white', justifyContent: 'start', 
            textAlgin: 'left', padding: 3, background: isVariant(),
            borderRadius: 2}}>
            <Typography variant={'h6'} sx={{alignSelf: 'center'}}>{props.title}</Typography>
        </Box>
        <Paper elevation={4} sx={{ justifyContent: 'center', width: '90%', margin: '0 auto' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column,index) => (
                  <TableCell
                    key={index}
                    align={column.align}
                    style={{ minWidth: column.width }}
                  >
                    {column.Header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? rows.map((row,index) => {
                console.log(row)
                const url = row.payment_url
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.accessor];
                        if(value) return (
                          <TableCell key={column.accessor}>
                            {value}
                          </TableCell>
                        );
                        if(column.Header === '#')return (
                          <TableCell key={index}>
                            <Stack direction="row" spacing={0}>
                                <Article />
                            </Stack>
                          </TableCell>
                        )
                        if(column.Header === 'Baixar')return (
                          <TableCell key={index}>
                            <Stack direction="row" spacing={0}>
                              <IconButton aria-label="list" color="info" onClick={() => view({url}) }>
                                <FileDownload />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  );
                }) : null}
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>
        </Box>
    )
}