import React from 'react'
import { Rotas } from './routes';
import { CssBaseline  } from '@mui/material';
import { EventProvider } from './context/EventContext';
import { OlderEventProvider  } from './context/OlderEventContext';

export default function App() {
  return  (
    <EventProvider>
      <OlderEventProvider>
        <CssBaseline/>
        <Rotas />
      </OlderEventProvider>
    </EventProvider>
  );
}