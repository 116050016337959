import React, {useEffect, useRef} from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography, Tabs, Tab, Button, TextField, Stack, Paper, Alert, Dialog, DialogTitle, DialogContent, IconButton} from '@mui/material'
import AppNav from '../../components/appNav';
import CircularProgress from '@mui/material/CircularProgress';
import TitleText from '../../components/TitleText'
import placeholderImage from '../../assets/placeholder-image.jpeg'
import CloseIcon from '@mui/icons-material/Close';
import { api,baseURL } from '../../services/api'
import { validateBr } from 'js-brasil';
import TablePayment from '../../components/TablePayment';
import { maskCNPJ, maskCPF2, maskTEL } from '../../utils/mask';
import { isPast, parseISO, isWithinInterval } from 'date-fns'

import './quill-custom.css'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3, width: "100%"}}>{children}</Box>
        )}
      </div>
    );
  }

export default function EventDetails(){
    const [tabStep,setTabStep] = React.useState(0)
    let { eventId } = useParams();
    const [eventFiltered, setEventFiltered] = React.useState([])
    const [imageThumb, setImagethumb] = React.useState()
    const [subscriber, setSubscriber] = React.useState()
    const [company, setCompany] = React.useState()
    const [open, setOpen] = React.useState(false);
    const [loadError, setLoadError] = React.useState()
    const [payments, setPayments] = React.useState([])
    const [genereteCertificate, setGenerateCertificate] = React.useState(false)
    const controlCertificate = isPast(parseISO(eventFiltered.date_finish))
    const controlSubscriber = useRef();
    
    let today = new Date();
    
    async function getEventById(id){
        try{
            const eventbyId = await api.get('/events/'+id+'?populate=*')
            setEventFiltered(...[eventbyId.data])
            let startDate = parseISO(eventbyId.data.start_subscription)
            let endDate = parseISO(eventbyId.data.end_subscription)
            controlSubscriber.current = !isWithinInterval(today,{start: startDate, end: endDate}) 
            setImagethumb({
                thumb: eventbyId.data.thumbnail !== null && eventbyId.data.thumbnail.formats.small?.url ? baseURL+eventbyId.data.thumbnail.formats.small?.url : placeholderImage,
                width: eventbyId.data.thumbnail !== null && eventbyId.data.thumbnail.formats.small?.width ? eventbyId.data.thumbnail.formats.small?.width : '600px',
                height: eventbyId.data.thumbnail !== null && eventbyId.data.thumbnail.formats.small?.height? eventbyId.data.thumbnail.formats.small?.height : '400px'
            })
            
        }catch(error){
            setLoadError(true)
            console.log(error)
        }
    }

    useEffect(() => {
        console.log(eventId)
       getEventById(eventId)
    },[])

    const columns = [
        { Header: "#", accessor: "icon", width: "100%" },
        { Header: "Boleto", accessor: "external_id", width: "100%" },
        { Header: "Baixar", accessor: "actions", width: "90%" },
    ];

    const handleView = ({url}) => {
        window.open(url);
    }
    const handleClose = () => {
        setOpen(false);
    };

    async function handlePayment(){
       const isCNPJ = validateBr.cnpj(company)
       if(!isCNPJ) { alert("CNPJ Inválido!")}
       try{
            const payment = await api.post('/order/payment/company', { data: { company, eventId } })
            if(payment.data.length == 0) return alert("Nehum boleto encontrado para este CNPJ")
            if(payment.data.length == 1) return window.open(payment.data[0].payment_url)
                setPayments(payment.data)
                setOpen(true);
       }catch(error){
            console.log(error)
       }
    }

    async function handleCertificate(){
        setGenerateCertificate(true)
        try{
            const certificate = await api.post('/certificates', { data: { document: subscriber, event: eventFiltered.name } })    
            if(certificate.status === 200){
                window.open(`${baseURL+certificate.data[0].url}`, '_blank');
            }
            setGenerateCertificate(false)
        }catch(error){
            console.log(error)
            setGenerateCertificate(false)
            alert(error.response.data.error.message)
        }
    }

    const handlePanel = (event, newStep) => {
        setTabStep(newStep)
    }   

    return(
        <Box sx={{width: "100vw", height:"100vh", px:0,m:0, overflowX: 'hidden'}}>
            <AppNav/>
            <Dialog
                onClose={handleClose}
                fullWidth
                maxWidth={'lg'}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Boletos Aguardando Pagamento
                </DialogTitle>
                <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <TablePayment title='Boletos' variant="info" view={handleView} rows={payments} columns={columns} />
                </DialogContent>
            </Dialog>
            {eventFiltered.length === 0 ? (
                <Stack
                sx={{display:'flex', width:'100%',height:'90%', justifyContent:'center'}}
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}>
                    {loadError ? <Alert sx={{width:'50%'}} severity="error">Erro ao carregar evento! Tente novamente mais tarde...</Alert> : <CircularProgress />}
                </Stack>
            ) 
            :
            <>  
            <Paper elevation={3} sx={{mx: 'auto', my: 1}} elevated={2} style={{width: imageThumb.width , height: imageThumb.height}}>
                {//<img src={`${imageThumb}`} alt='thumbnail-event' style={{resizeMode:'contain', aspectRatio:'1', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height:'100%',width:'100%' }} />
                }
                 <Box style={{background: `url(${imageThumb.thumb}`, resizeMode:'contain', backgroundRepeat: 'no-repeat', height:'100%', width:'100%'}}>
                 {eventFiltered.sold_out ? 
                        <Box style={{ display: 'flex', backgroundColor: "rgba(0,0,0,0.6)",width:'100%', justifyContent: 'center', textAlign:'center', height: "100%",borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                            <Typography color={'#FF8787'} sx={{alignSelf:'center'}} variant={'h3'}>ESGOTADO</Typography>
                        </Box>
                : null }
                </Box>
            </Paper>
            <Box sx={{display:'flex', width: '100%', bgcolor: 'background.paper', justifyContent:'center'}} >
                <Tabs value={tabStep} onChange={handlePanel}  arial-label="tab panel event" variant="scrollable" scrollButtons={true}>
                    <Tab label="Apresentação" id="simple-tab-0" aria-controls="simple-tabpanel-0"/>
                    <Tab label="Inscrições" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
                    <Tab label="Local" id="simple-tab-2" aria-controls="simple-tabpanel-2"/>
                    <Tab label="Hospedagem" id="simple-tab-3" aria-controls="simple-tabpanel-3"/>
                    <Tab label="Programação" id="simple-tab-4" aria-controls="simple-tabpanel-4"/>
                    <Tab label="2ª Via Boleto" id="simple-tab-5" aria-controls="simple-tabpanel-5"/>
                    <Tab disabled={!controlCertificate} label="2ª Via Certificado" id="simple-tab-6" aria-controls="simple-tabpanel-6" />
                </Tabs>
            </Box>
            <Box sx={{ width: '100%', mb: 5, py:4 }}>
                <TabPanel value={tabStep} index={0}>
                    <TitleText>O EVENTO</TitleText>
                    <Typography variant={"body1"} sx={{width: "80%", mt: 3, mb: 5, mx: "auto"}} textAlign="justify">
                    <div dangerouslySetInnerHTML={{ __html: eventFiltered.apresentation}}></div>
                    </Typography>
                </TabPanel>
                <TabPanel value={tabStep} index={1}>
                    <TitleText>DAS INSCRIÇÕES</TitleText>
                    <Button disabled={controlSubscriber.current || eventFiltered.sold_out } variant={"contained"} sx={{ display: "block", mt:5, mx: "auto", borderRadius: 5, textAlign: 'center', maxWidth: { xs: '60%', sm: '40%' } }}  href={`/subscribe/${eventId}`}>
                        <Typography  variant='h6'>Inscreva-se</Typography>
                    </Button>
                    <Typography variant={"body1"} sx={{width: "80%", mt: 3, mb: 5, mx: "auto"}} textAlign="justify">
                        <div dangerouslySetInnerHTML={{ __html: eventFiltered.terms}}></div>
                    </Typography>

                </TabPanel>
                <TabPanel value={tabStep} index={2}>
                <TitleText>DO LOCAL</TitleText>
                    <Typography variant={"h5"} sx={{width: "80%", my: 3, mx: "auto", fontWeight: 500, color: '#1D385E'}} textAlign="justify">
                        Local: {eventFiltered.location_address}
                    </Typography>
                    <Typography variant={"body1"} sx={{width: "80%", mt: 3, mb: 5, mx: "auto"}} textAlign="justify">
                        <div dangerouslySetInnerHTML={{ __html: eventFiltered.location}}></div>
                    </Typography>
                </TabPanel>
                <TabPanel value={tabStep} index={3}>
                    <TitleText>DA HOSPEDAGEM</TitleText>
                    <Typography variant={"body1"} sx={{width: "80%", mt: 3, mb: 5, mx: "auto"}} textAlign="justify" >
                        <div dangerouslySetInnerHTML={{ __html: eventFiltered.information_accommodation}}></div>
                    </Typography>
                </TabPanel>
                <TabPanel value={tabStep} index={4}>
                    <TitleText>PROGRAMAÇÃO</TitleText>
                    <Typography variant={"body1"} sx={{width: "80%", mt: 3, mb: 5, mx: "auto"}} textAlign="justify">
                        <div dangerouslySetInnerHTML={{ __html: eventFiltered.scheduling}}></div>
                    </Typography>
                </TabPanel>
                <TabPanel value={tabStep} index={5}>
                    <TitleText>2ª VIA BOLETO</TitleText>
                    <TextField fullWidth sx={{ display: "block", m:5 ,width: "60%", mx: "auto" }} 
                    id="CNPJ" label="CNPJ" variant="outlined" name="CNPJ"
                    value={company}
                    onChange={(e) => setCompany(maskCNPJ(e.currentTarget.value))}
                    />
                    <Button variant={"contained"} sx={{ display: "block", width: "40%", mx: "auto", borderRadius: 5}} onClick={handlePayment}>
                        <Typography variant='h6'>BUSCAR</Typography>
                    </Button>
                </TabPanel>
                <TabPanel value={tabStep} index={6}>
                <TitleText>2ª VIA DO CERTIFICADO</TitleText>
                <TextField fullWidth 
                    sx={{ display: "block", m:5 ,width: "60%", mx: "auto" }}  
                    inputProps={{ maxLength: 14 }} 
                    value={subscriber} 
                    id="cpf" 
                    label="CPF" 
                    variant="outlined" 
                    name="cpf" 
                    onChange={(e)=> setSubscriber(maskCPF2(e.currentTarget.value))}/>
                   <Button 
                        onClick={() => handleCertificate() } 
                        variant={"contained"} 
                        sx={{ display: "block", width: "40%", mx: "auto", borderRadius: 5}}>
                        <Typography variant='h6'>{genereteCertificate ? 'gerando certificado' :'buscar certificado'}</Typography>
                    </Button>
                </TabPanel>
            </Box>
            </>}
        </Box>
    )
}