import React, { useRef, useState, useEffect } from 'react'
import { Swiper, SwiperSlide} from "swiper/react";
import { Controller } from "swiper";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Box,Grid, Paper, ButtonGroup, IconButton, CircularProgress, Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import AppNav from '../../components/appNav';
import placeholderImage from '../../assets/placeholder-image.jpeg'
import { useEvent } from '../../context/EventContext';
import { api, baseURL } from '../../services/api';
import 'swiper/css';
import 'swiper/css/bundle';
import * as qs from 'qs';




export default function Events(){
    let navigate = useNavigate()
    const [swiperRef, setSwiperRef] = useState(null);
    const { events, setEvents } = useEvent()
    const currentPage = useRef(1)
    const [loaded, setLoaded] = useState(false)
    const lastEvent = useRef(false)
    const query = useRef(null)
    const [stateAlert, setStateAlert] = React.useState({status:false, type: 'success', msg:''});
    

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleResquest = async () => {
        query.current = qs.stringify({
            filters:{
                isActive: true,
                isVisible: true,
                deleted: false
            },
            sort: ['createdAt:desc'],
            populate: '*',
            pagination: {
                pageSize: 6,
                page: currentPage.current,
            },
            encodeValuesOnly: true,
    
        })
        if(lastEvent.current) return
        setLoaded(true)
        try{
            const { data } = await api.get('/events?'+query.current)
            if(currentPage.current === 1 && events.length > 0) {
                setLoaded(false)
                return;
            }
            if (!data.data.length){
                setLoaded(false)
                lastEvent.current = true
                return;
            }
            setLoaded(false)
            setEvents([...events,...data.data])
        }catch(error){
            setLoaded(false)
            setEvents([])
            setStateAlert({status:true,type: "error", msg:"Erro ao carregar eventos! Tente novamente mais tarde!"})
        }
    }
    const handleEvent = (id) => {
        navigate(`/event/${id}`)
    }

    const handleNextEvents = async () => { 
        currentPage.current = currentPage.current+1
        try {
            handleResquest()
            swiperRef.slideNext()
        }catch(error){
            currentPage.current = currentPage.current-1
        }
    }
    
    useEffect(() => {
        if(events.length > 0){
            currentPage.current = 2
        }
      handleResquest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <>
        <Box sx={{width: "100vw", height:"100vh", px:0,m:0, backgroundColor: "#EEEEEE"}}>
            <AppNav/>
            <Snackbar
                open={stateAlert.status}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
                onClose={() => setStateAlert({status: false, type: 'error', msg:'' } )} >
                    <Alert sx={{ width: '100%' }} severity={`${stateAlert.type}`}>
                        {stateAlert.msg}
                    </Alert>
            </Snackbar>
            <Grid container columns={12} spacing={3} p={5} sx={{backgroundColor: "#EEEEEE" }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Box sx={{display:"flex", width:'100%',px:0,m:0, textAlign: 'center', justifyContent: "space-between"}}>
                        <Typography sx={{width: { xs: '0%', sm: '33%'}}}></Typography>
                        <Typography sx={{width: { xs: '50%', sm: '33%'}, color: "#004D91", alignContent:'center', textAlign:'center', alignSelf:'center', fontWeight: 700, fontSize: '1.5em'}}>
                            Próximos Eventos
                        </Typography> 
                        <ButtonGroup sx={{width: { xs: '50%', sm: '33%'},display:'flex',justifyContent:'end'}}>
                        <IconButton  aria-label="previus" onClick={() => swiperRef.slidePrev()}>
                                <KeyboardArrowLeftIcon  sx={{ fontSize: '1.5rem', color:"#004D91" }} />
                            </IconButton> 
                        <IconButton  aria-label="next" onClick={() => handleNextEvents()}>
                                <KeyboardArrowRightIcon sx={{ fontSize: '1.5rem', color:"#004D91" }} />
                        </IconButton>
                        </ButtonGroup>
                    </Box>
                </Grid>
                {loaded ? <Grid item sx={{textAlign: 'center'}} sm={12}><CircularProgress /></Grid> : null}
                <Swiper
                    onSwiper={setSwiperRef}
                    onReachEnd={handleNextEvents}
                    centeredSlides={false}
                    slidesPerView={'auto' }
                    autoHeight={false}
                    loopedSlides={events.length}
                    loop={false}
                    spaceBetween={40}
                    modules={[Controller]}
                    className="swiper"
                    style={{padding: '25px 10px'}}
                >
                {events.length > 0 ? events.map((event,index) => {
                return(
                    <SwiperSlide key={event.id} style={{maxWidth:'275px', width: '100%'}}>
                    <Paper elevation={3} sx={{ borderRadius: 3}}>
                        <Box sx={{textAlign: 'right', backgroundColor:"#fff", borderRadius: 3, height: '375px', display:'flex', flexDirection: 'column', justifyContent:'space-between'}}>
                            <Box style={{
                                background: `url(${event.attributes.thumbnail.data !== null ? baseURL+event.attributes.thumbnail.data.attributes.url : placeholderImage })`,height: "190px", 
                                backgroundSize: 'cover', backgroundRepeat: 'no-repeat',layout:"fill" ,backgroundPosition: 'center', borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                                {event.attributes.sold_out ? 
                                        <Box style={{ display: 'flex', backgroundColor: "rgba(0,0,0,0.6)",width:'100%', justifyContent: 'center', textAlign:'center', height: "100%",borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                                            <Typography color={'#FF8787'} sx={{alignSelf:'center'}} variant={'h3'}>ESGOTADO</Typography>
                                        </Box>
                                    : null }
                            </Box>
                            <Typography variant={"body1"} 
                                sx={{display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2,
                                     textAlign: 'left', mx:2, my:1,fontWeight: 500, 
                                     color:"#004D91", textTransform: 'uppercase' }}>
                                    {event.attributes.name}
                            </Typography> 
                            <Box sx={{ display: "flex", mx:2, mt:1, textAlign: 'left', color:"#0097fe"}}>
                                <LocationOnIcon fontSize="small"  />
                                <Typography sx={{display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2,textAlign:'left', color:"#2A6393"}} variant={"body2"}>
                                    {event.attributes.location_address}</Typography>
                            </Box>
                            <Button size="small" variant="contained"  onClick={() => handleEvent(event.id)} sx={{m:2, p: 1,backgroundColor:"#004D91"}}>
                                INFORMAÇÃO & INSCRIÇÃO
                            </Button> 
                        </Box>
                    </Paper>
                    </SwiperSlide>
                    )}
                ) : <SwiperSlide></SwiperSlide>} 
               </Swiper>
           </Grid>
        </Box>
        </>
    )
}