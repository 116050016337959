import * as React from 'react';
import * as yup from 'yup';
import {Typography, TextField, Grid, Box, Button } from '@mui/material';
import { validateBr } from 'js-brasil';
import { maskCNPJ } from '../../utils/mask'
import { useFormik } from 'formik';
import { FormContext } from '../../context/FormContext';
import { CompanyContext } from '../../context/CompanyContext';
import {api} from '../../services/api'

export default function Payer(props) {
  const { company, setCompany } = React.useContext(CompanyContext);
  const { activeStep, setActiveStep } = React.useContext(FormContext);

  const documentSchema = yup.object({
      document: yup.string()
      .min(5,'CNPJ inválido')
      .test("is-cnpj","CNPJ Inválido", value => validateBr.cnpj(value))
      .required('CNPJ precisa ser preenchido')
  })

  const documentValidation = useFormik({
    initialValues: {
      document: company.document || ""
    },
    validationSchema: documentSchema,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values) => {
      console.log(values.document)
      const cnpj = values.document.replace('/','%2F');
      if(validateBr.cnpj(values.document)){
        try{
          const enterprise = await api.get('/companies/'+cnpj)
          enterprise.status !== 200 ? setCompany({document: values.document}) : setCompany(enterprise.data)
          setActiveStep(activeStep + 1)
        }catch(error){
          setCompany({document: values.document})
          setActiveStep(activeStep + 1)
        }
          
      } 
    },
  });

   function maskDocument(e){
     if(e.target.value.length > 18){ return }
     if(e.target.value.length > 0 ){ return documentValidation.setFieldValue('document',maskCNPJ(e.target.value)) }
     return documentValidation.setFieldValue('document',e.target.value)
  }
  return (
    <React.Fragment>
      <form onSubmit={documentValidation.handleSubmit}>
      <Typography variant="h6" gutterBottom>
        Instituição Pagadora
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            id="document"
            name="document"
            label="CNPJ"
            fullWidth
            color={validateBr.cnpj(documentValidation.values.document || '') ? 'success' : 'error'}
            value={documentValidation.values.document}
            onChange={(e) => maskDocument(e)}
            error={documentValidation.touched.document && Boolean(documentValidation.errors.document)}
            helperText={documentValidation.touched.document && documentValidation.errors.document}
            />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          type={'submit'}
          sx={{ mt: 3, ml: 1 }} >
            Próximo
        </Button>
      </Box>
      </form>
    </React.Fragment>
  );
}