import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { api, baseURL } from '../../services/api'
import { Box, Typography, Grid, TextField, Button, Stack } from '@mui/material'
import AppNav from '../../components/appNav';
import { validateBr } from 'js-brasil';
import { isWithinInterval, parseISO } from 'date-fns'
import { maskCPF2, maskTEL } from '../../utils/mask';
import { useFormik } from 'formik';

export default function Checkin() {
    let { eventId } = useParams();
    const [eventFiltered, setEventFiltered] = React.useState([])
    const [loadError, setLoadError] = React.useState()
    const [sucess,setSucess] = React.useState()
    const [subscriber, setSubscriber] = React.useState()

    const chekinSchema = yup.object({
        document: yup.string()
            .min(5, 'CPF inválido')
            .test("is-cnpj", "CPF Inválido", value => validateBr.cpf(value || " "))
            .required('CPF precisa ser preenchido'),
        name: yup.string()
            .min(5, 'Nome inválido (muito curto)')
            .required('Nome precisa ser preenchido'),
        email: yup.string()
            .email('E-mail inválido')
            .required('Email precisa ser preenchido'),
        whatsapp: yup.string()
            .min(15, 'Celular inválido')
            .required('Whatsapp precisa ser preenchido'),
    })
    const checkinValidation = useFormik({
        initialValues: {
            event: eventFiltered.id,
            name: "",
            email: "",
            whatsapp: "",
            document: ""
        },
        validationSchema: chekinSchema,
        onSubmit: values => {
            handleCheckin(values)
        },
    });

    async function handleCheckin(values){
        const data = {
            "document": values.document,
            "name": values.name,
            "email": values.email,
            "whatsapp": values.whatsapp,
            "event": eventId
        } 
        try{
            const register = await api.post('/checkins', {data: data})
            if(register.status === 200){
                alert('Check-in Realizado com Sucesso!')
                setSucess('Presença confirmada com sucesso!')
                checkinValidation.resetForm()
            }
        }catch(err){
            setLoadError(err.message)
            console.log(err)
        }
    }

    async function getEventById(id) {
        try {
            const eventbyId = await api.get('/events/' + id + '?populate=*')
            setEventFiltered(...[eventbyId.data])
            const {isActive, date_initial, date_finish } =  eventbyId.data
            const isCheckin = isWithinInterval(new Date(), { start: parseISO(date_initial), end: parseISO(date_finish)})
            if(isCheckin == false){ setLoadError("Evento não pode receber checkins no momento")}
        } catch (error) {
            setLoadError(true)
            console.log(error)
        }
    }

    useEffect(() => {
        console.log(eventId)
        getEventById(eventId)
    }, [])
    return (
        <Box sx={{ width: "100vw", height: "100vh", px: 0, m: 0 }}>
            <AppNav />
            <Grid container my={10} columns={12} sx={{ justifyContent: 'center' }}>
                <Grid item sm={12} px={2} sx={{ display: 'flex', justifyContent: 'center', alignText: 'center' }}>
                    <Typography><span style={{fontSize: 22}}>Check-in do Evento:</span> <br/> {eventFiltered.name}</Typography>
                </Grid>
                <Grid item sm={12} py={3} sx={{ display: 'flex', justifyContent:    'center', alignText: 'center' }}>
                    <Typography color={'green'}>{sucess}</Typography>
                </Grid>
                <Grid item sm={12} py={3} sx={{ display: 'flex', justifyContent:    'center', alignText: 'center' }}>
                    <Typography color={'red'}>{loadError}</Typography>
                </Grid>
            </Grid>
            {(loadError == true || loadError.length > 0) ? null :
            <form onSubmit={checkinValidation.handleSubmit}>
                <Grid container direction={'column'} px={5} columns={10} spacing={2} >
                    <Grid item xs={10} md={2}>
                        <TextField
                            id="name"
                            variant="outlined"
                            label="Nome Completo"
                            name="name"
                            fullWidth
                            value={checkinValidation.values.name}
                            onChange={checkinValidation.handleChange}
                            error={checkinValidation.touched.name && checkinValidation.errors.name}
                            helperText={checkinValidation.touched.name && checkinValidation.errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                             id="document"
                             variant="outlined"
                             label="CPF"
                             name="document"
                             fullWidth
                             value={checkinValidation.values.document}
                             onChange={checkinValidation.handleChange}
                             error={checkinValidation.touched.document && checkinValidation.errors.document}
                             helperText={checkinValidation.touched.document && checkinValidation.errors.document}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            id="email"
                            variant="outlined"
                            label="Email"
                            name="email"
                            fullWidth
                            value={checkinValidation.values.email}
                            onChange={checkinValidation.handleChange}
                            error={checkinValidation.touched.email && checkinValidation.errors.email}
                            helperText={checkinValidation.touched.email && checkinValidation.errors.email}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                           id="whatsapp"
                           variant="outlined"
                           label="Whatsapp"
                           name="whatsapp"
                           fullWidth
                           value={checkinValidation.values.whatsapp}
                           error={checkinValidation.touched.whatsapp && checkinValidation.errors.whatsapp}
                           helperText={checkinValidation.touched.whatsapp && checkinValidation.errors.whatsapp}
                            onChange={(e) => {
                                    let whats = maskTEL(e.target.value)
                                    checkinValidation.setFieldValue('whatsapp', whats)
                                }
                            }
                        />
                    </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="contained"
                        type={'submit'}
                        sx={{ mt: 3, ml: 1 }}>
                        Realizar Check-in
                    </Button>
                </Box>
                </Grid>
            </form>
            }
        </Box>
    )
}